import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

export default function FormControlLabelPosition(props) {
    const [selected_months, set_selected_months] = React.useState(['August']);
    var my_months = { 'March': 3, 'April': 4, 'May': 5, 'June': 6, 'July': 7, 'August': 8, 'September': 9, 'October': 10, 'November': 11, 'December': 12 },
        months_option = ['March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{height:55,backgroundColor:"rgb(237, 237, 237,0.6)"}}>
            <p style={{marginRight:20,fontSize:18,fontWeight:600}}>Select Months:</p>
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    {months_option.map(item => <FormControlLabel value="start" control={<Checkbox sx={{ padding: "2px",'&, &.Mui-checked': {color: '#828282'} }} checked={selected_months.includes(item)?true:false} onClick={()=>{
                        var temp=[]
                        if(selected_months.includes(item)){temp=selected_months.filter(item2=>item2!=item)}
                        else{temp=[...selected_months,item]}
                        set_selected_months(temp)
                        var max=0
                        var final_month=""
                        temp.map(month=>{
                            if(max<my_months[month]){
                                max=my_months[month]
                                final_month=month
                            }
                        })
                        props.set_selcted_month(final_month)
                    }}/>} label={item} labelPlacement="end" />)}
                </FormGroup>
            </FormControl>

        </Grid>
    );
}
