import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import * as d3 from 'd3';
import * as $ from 'jquery'
import { connect } from "react-redux";
import * as algo from "./Algo"
import ClusterItem from './ClusterItem';
import Popper from './Popper';
class App extends Component {
    constructor(props) {
        super(props);
        this.state = { temp: 0, selected_topic: "none", selected_phrase: "none", popper_open: false,xy:[0,0],selected_cluster:"none" };
    }
    componentDidMount() {
        this.setState({ temp: 10 })
        var filtered_data=this.props.month_wise_data.filter(item=>item['Topics']==this.props.selected_topic && item['KMeans_cluster']==this.state.selected_cluster).map(item=>item["my_index"])
        this.props.Set_selected_circles(filtered_data) 
    }
    componentDidUpdate(prevProps, prevState) {
        var item_width = $(".topic_svg").width()
        var item_height = $(".topic_svg").height()
        var svg1_height = 20, item1_top_margin = 6
        var svg2_height = item_height - (svg1_height + item1_top_margin + 8)
        var svg2_width = item_width
        //var svg = d3.select("#" + this.props.myid)
    }
    handle_cluster_click=()=>{
        var filtered_data=this.props.month_wise_data.filter(item=>item['Topics']==this.props.selected_topic && item['KMeans_cluster']==this.state.selected_cluster).map(item=>item["my_index"])
       this.props.Set_selected_circles(filtered_data) 
       this.props.Set_selected_phrase(null)
    }
    render() {
        var parent_width = this.props.cluster_view_container_width - 30
        var parent_height = this.props.cluster_view_container_height
        var topic_wise_data = this.props.month_wise_data.filter(item => item['Topics'] == this.props.selected_topic)
        var groupedData = d3.nest().key((d) => d['KMeans_cluster']).entries(topic_wise_data).sort((a, b) => a.key - b.key)
        var item_width = parent_width / 4
        var item_height = this.props.cluster_view_container_height - 12
        var parent_width = item_width * groupedData.length + 50
        return (
            <Grid container direction="row" alignItems="flex-start" style={{ paddingLeft: 10, paddingRight: 0, width: parent_width, backgroundColor: "rgb(237, 237, 237,0.2)" }}>
                {groupedData.map((item, i) => <Grid item className='cluster_child' style={{ width: item_width, height: item_height, marginRight: 4, marginTop: 8, opacity:this.state.selected_cluster!="none" && this.state.selected_cluster!=item.key?0.5:1 }} onContextMenu={(e) => {
                    e.preventDefault()
                    this.setState({selected_cluster:item.key})
                    this.setState({xy:[e.pageX,e.pageY]})
                    this.setState({ popper_open: true })
                }} onClick={()=>{this.setState({selected_cluster:item.key})}}>
                    <ClusterItem myid={"cluster" + i} item_width={item_width} item_height={item_height} data={item} Set_selected_phrase={(selected_phrase) => this.props.Set_selected_phrase(selected_phrase)} selected_phrase={this.props.selected_phrase}> </ClusterItem>
                </Grid>)}
                <Popper myid="cluster" handle_cluster_click={this.handle_cluster_click} xy={this.state.xy} popper_open={this.state.popper_open} set_popper_open={(val) => this.setState({ popper_open: val })}> </Popper>
            </Grid>

        );
    }
}

const maptstateToprop = (state) => {
    return {
        original_data: state.original_data,
        month_wise_data: state.month_wise_data,
        default_month: state.default_month,
        selected_topic: state.selected_topic,
        selected_phrase: state.selected_phrase,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_original_data: (val) => dispatch({ type: "original_data", value: val }),
        Set_month_wise_data: (val) => dispatch({ type: "month_wise_data", value: val }),
        Set_selected_topic: (val) => dispatch({ type: "selected_topic", value: val }),
        Set_selected_phrase: (val) => dispatch({ type: "selected_phrase", value: val }),
        Set_selected_circles: (val) => dispatch({ type: "selected_circles", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);


//https://www.d3indepth.com/force-layout/
