import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import * as d3 from 'd3';
import * as $ from 'jquery'
import { connect } from "react-redux";
import * as algo from "./Algo"
import ClusterItem from './ClusterItem';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { temp: 0, q_data: [], selected_quadrant: 0};
    }
    componentDidMount() {
        this.setState({temp:10})
    }
    componentDidUpdate(prevProps, prevState) {
        var selected_data=this.props.month_wise_data.filter(item=>this.props.selected_circles.includes(item['my_index']))
        console.log(selected_data)
        algo.create_matrix(selected_data,this.state.selected_quadrant,this.set_quadrant_data)
        algo.create_bar(this.state.q_data)
    }
    set_quadrant_data=(q_data,selected_quadrant)=>{
        this.setState({q_data:q_data,selected_quadrant:selected_quadrant})
    }

    render() {
        var padding=10
        var parent_width= this.props.parent_width - padding*4
        var parent_height=this.props.parent_height
        var item1_width=300,item1_height=parent_height,item2_width=200,item2_height=parent_height,
        item3_width=parent_width-(item1_width+item2_width+10),item1_height=parent_height
        

        return (
            <Grid container direction="row" alignItems="flex-start" style={{width:"100%",padding:padding}}>
                <Grid item style={{width:item1_width,height:item1_height,backgroundColor:"",marginRight:10}}><svg className="matrix_svg" style={{width:"100%",height:"100%"}}></svg></Grid>
                {this.state.q_data.length>0?<Grid item style={{width:item2_width,height:item1_height,backgroundColor:"",marginRight:10}}> <svg className="bar_chart_svg" style={{width:"100%",height:"100%"}}></svg></Grid>:null}
                {this.state.q_data.length>0?<Grid item style={{width:item3_width,height:item1_height,backgroundColor:"gray"}}> Facets</Grid>:null}
            </Grid>

        );
    }
}

const maptstateToprop = (state) => {
    return {
        original_data: state.original_data,
        month_wise_data: state.month_wise_data,
        default_month: state.default_month,
        selected_topic: state.selected_topic,
        selected_phrase: state.selected_phrase,
        selected_circles:state.selected_circles
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_original_data: (val) => dispatch({ type: "original_data", value: val }),
        Set_month_wise_data: (val) => dispatch({ type: "month_wise_data", value: val }),
        Set_selected_topic: (val) => dispatch({ type: "selected_topic", value: val }),
        Set_selected_phrase: (val) => dispatch({ type: "selected_phrase", value: val }),
        Set_selected_circles: (val) => dispatch({ type: "selected_circles", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);


//https://www.d3indepth.com/force-layout/
