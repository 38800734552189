import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import * as d3 from 'd3';
import * as $ from 'jquery'
import { connect } from "react-redux";
import december from "./Data/csv/December.csv";
import Months from "./Components/Months"
import Table from "./Components/Table"
import TopicView from './Components/TopicView';
import ClusterView from './Components/ClusterView';
import "./App.css"
import FacetView from './Components/FacetView';
class App extends Component {
    constructor(props) {
        super(props);
        this.state = { temp: 0 };
    }
    componentDidMount() {
        d3.csv(december).then(original_data => {
            var processed_original_data=original_data.map(item=>{
                item['KMeans_x_component']=parseFloat(item['KMeans_x_component'])
                item['KMeans_y_component']=parseFloat(item['KMeans_y_component'])
                item['my_index']=parseFloat(item['my_index'])
                item['FlairSentimentScore']=parseFloat(item['FlairSentimentScore'])
                item['SubjectivityScore']=parseFloat(item['SubjectivityScore'])        
            })
            this.props.Set_original_data(original_data)
            this.props.Set_month_wise_data(original_data.filter(item => item['Month'] == this.props.default_month))
        })
    }
    componentDidUpdate() {
        //console.log(this.props.original_data)
    }
    render() {
        var Topic_groupedData = d3.nest().key((d) => d['Topics']).entries(this.props.month_wise_data).sort((a, b) => b['values'].length - a['values'].length)
        var table_width = 360
        var top_height = 56
        var parent2_height = window.innerHeight - top_height, parent2_width = window.innerWidth
        var topic_cluster_view_container_width = parent2_width - table_width, topic_cluster_view_container_height = parent2_height
        var topic_view_container_height = topic_cluster_view_container_height / 2, topic_view_container_width = topic_cluster_view_container_width
        var cluster_view_container_height = (topic_cluster_view_container_height / 4) - 10, cluster_view_container_width = topic_cluster_view_container_width
        var topic_view_width = 263, topic_view_height = 290,topicview_margin_right=8

        return (
            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ width: window.innerWidth }}>
                <Grid style={{ height: top_height, width: "100%" }}>
                    <Months default_month={this.props.default_month} set_selcted_month={(month) => this.props.Set_month_wise_data(this.props.original_data.filter(item => item['Month'] == month))}></Months>
                </Grid>
                <Grid className="parent2" item container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ height: parent2_height, width: parent2_width, backgroundColor: "" }}>
                    <Grid className="topic_cluster_view_container" item container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginTop: 10, overflow: "scroll", height: topic_cluster_view_container_height, width: topic_cluster_view_container_width - 8, marginLeft: 8 }}>
                        
                        <Grid className='topic_view_container'
                        style={{ marginTop: 0, overflow: "scroll", height: topic_view_height+20, width: topic_view_container_width, padding: 0 }}>
                            <div className="scrolable_topic" style={{width:Topic_groupedData.length*(topic_view_width+topicview_margin_right),height: 160}}>
                                {Topic_groupedData.map((item, i) => <div item style={{ height: 150, marginTop: 5,marginRight:topicview_margin_right,display:"inline-block" }}><TopicView topic_view_width={topic_view_width} topic_view_height={topic_view_height} topic={item.key} myid={"topic" + i} Topic_groupedData={Topic_groupedData} d1={item}> </TopicView></div>)}
                            </div>
                        </Grid>
                        
                        <Grid className='cluster_view_container' container direction="row" alignItems="flex-start" spacing={"5px"} rowSpacing={{ sx: "5px" }}
                            style={{ marginTop: 10, height: 10+cluster_view_container_height*2, width: cluster_view_container_width, padding: 0}}>
                            <ClusterView cluster_view_container_width={cluster_view_container_width} cluster_view_container_height={cluster_view_container_height} > </ClusterView>
                        </Grid>
                        {this.props.selected_circles.length>0?
                        <Grid className='quadrant_view_container' item container direction="row" alignItems="flex-start" spacing={"5px"} rowSpacing={{ sx: "5px" }}
                            style={{ marginTop: 10, height: 360,width: cluster_view_container_width, padding: 0,backgroundColor:""}}>
                            <FacetView parent_width={cluster_view_container_width} parent_height={360}></FacetView>
                        </Grid>:null}

                    </Grid>
                    <Grid item style={{ width: table_width }}>
                        <Table selected_circles={this.props.selected_circles} month_wise_data={this.props.month_wise_data} selected_topic={this.props.selected_topic} selected_phrase={this.props.selected_phrase}></Table>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const maptstateToprop = (state) => {
    return {
        original_data: state.original_data,
        month_wise_data: state.month_wise_data,
        default_month: state.default_month,
        selected_topic: state.selected_topic,
        selected_phrase: state.selected_phrase,
        selected_circles:state.selected_circles
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_original_data: (val) => dispatch({ type: "original_data", value: val }),
        Set_month_wise_data: (val) => dispatch({ type: "month_wise_data", value: val })
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);


//https://www.d3indepth.com/force-layout/
