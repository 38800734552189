import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import * as d3 from 'd3';
import * as $ from 'jquery'
import { connect } from "react-redux";
import * as algo from "./Algo"
class App extends Component {
    constructor(props) {
        super(props);
        this.state = { temp: 0,selected_topic:"none" };
    }
    componentDidMount() {
        this.setState({temp:10})
    }
    componentDidUpdate(prevProps, prevState) {
        var item_width=$(".topic_svg").width()
        var item_height=$(".topic_svg").height()
        var svg1_height=25,item1_top_margin=6
        var svg2_height=item_height-(svg1_height+item1_top_margin+8)
        var svg2_width=item_width
        var svg=d3.select("#"+this.props.myid)
        
        var svg1=svg.selectAll(".svg1").data([0]).join('svg').attr("class","svg1").attr("x",0).attr("y",item1_top_margin).attr("width",item_width).attr("height",svg1_height)
        //svg1.selectAll(".my_rect").data([0]).join('rect').attr("class", "my_rect").attr("width", "100%").attr("height", "100%").style("fill", "grey")
        //algo.Createtopic_viz(this.props.d1, this.props.Topic_groupedData, svg1,item_width,svg1_height)
        var svg2=svg.selectAll(".svg2").data([0]).join('svg').attr("class","svg2").attr("x",0).attr("y",svg1_height+item1_top_margin+5).attr("width",svg2_width).attr("height",svg2_height)
        
        var expert_svg = svg2.selectAll(".expert_svg").data([0]).join('svg').attr("class","expert_svg").attr("x",0).attr("y",0).attr("width",svg2_width/2).attr("height",svg2_height)
        //expert_svg.selectAll(".my_rect").data([0]).join('rect').attr("class", "my_rect").attr("width", "100%").attr("height", "100%").style("fill", "grey")
        //var expert_data=this.props.d1.filter(item=>item["Label"=="Expert"])
        algo.Createtopic_viz(this.props.d1, this.props.Topic_groupedData, expert_svg,svg2_width/2,svg2_height,"Expert")
        var non_expert_svg = svg2.selectAll(".non_expert_svg").data([0]).join('svg').attr("class","non_expert_svg").attr("x",svg2_width/2).attr("y",0).attr("width",svg2_width/2).attr("height",svg2_height)
        //non_expert_svg.selectAll(".my_rect").data([0]).join('rect').attr("class", "my_rect").attr("width", "100%").attr("height", "100%").style("fill", "grey")
        algo.Createtopic_viz(this.props.d1, this.props.Topic_groupedData, non_expert_svg,svg2_width/2,svg2_height,"Non Expert")
    }
    render() {
        return (
            <div className='topic_parent' style={{width:this.props.topic_view_width,height:this.props.topic_view_height, backgroundColor: "rgb(237, 237, 237,0.6)",textAlign:"center",fontSize:14,cursor:"pointer",opacity:this.props.topic==this.props.selected_topic || this.props.selected_topic=="none"?1:0.3}} 
            onClick={()=>{
                this.props.Set_selected_topic(this.props.topic)
            }}>
            <div className='topic_text' style={{height:35,paddingTop:5}}>{this.props.topic}</div>
            <svg className='topic_svg' id={this.props.myid} style={{width:"100%",height:$(".topic_parent").height()-$(".topic_text").height()}}> </svg>
            </div>
        );
    }
}

const maptstateToprop = (state) => {
    return {
        original_data: state.original_data,
        month_wise_data: state.month_wise_data,
        default_month: state.default_month,
        selected_topic:state.selected_topic,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_original_data: (val) => dispatch({ type: "original_data", value: val }),
        Set_month_wise_data: (val) => dispatch({ type: "month_wise_data", value: val }),
        Set_selected_topic: (val) => dispatch({ type: "selected_topic", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);


//https://www.d3indepth.com/force-layout/
