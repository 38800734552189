import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import * as $ from 'jquery';


const useStyles = makeStyles({
  table: {
    // minWidth: $(".ShowTweetsContainer").width()
  },
  cell_short: {
    paddingTop: 0,
    paddingBottom: 3,
    fontSize: "12px",
    maxWidth: $(".ShowTweetsContainer").width(),
    overflowWrap: "anywhere",
    //backgroundColor:"#fcfcfc",
  },
  selected_cell:{
    paddingTop: 6,
    paddingBottom: 3,
    fontSize: "12px",
    maxWidth: $(".ShowTweetsContainer").width(),
    overflowWrap: "anywhere",
    backgroundColor:"#f2f2f2",
  }
});
export default function MyTable(props) {
  const {setProps,value } = props;
  const rows = props.data
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rows.length);
  const [selected_tweet_id, set_selected_tweet_id] = React.useState("");
  
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    //table_parent_container 90
    <div>
      <div className="keyword_title" style={{ position: "sticky", top: "0", backgroundColor: "#E2E2E2", marginTop: -5, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><p style={{ margin: 0, marginBottom: 0, textTransform: "capitalize", fontWeight: "bold" }}>{props.header_text}</p></div>
      <TableContainer component="div" style={{overflow:"scroll", marginTop:5,height:  window.innerHeight-145, maxWidth: $(".ShowTweetsContainer").width(), width: $(".ShowTweetsContainer").width() }}>
        <Table className="mytable" id={props.header_text} aria-label="simple table">
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={Math.random()}>
                  <TableCell align="strt" className={selected_tweet_id==row["my_index"]?classes.selected_cell:classes.cell_short} onClick={()=>{
                    if(selected_tweet_id==row["my_index"]){
                      set_selected_tweet_id("None")
                      setProps({"value":"None"})
                    }
                    else{
                      set_selected_tweet_id(row["my_index"])
                      setProps({"value":String(row["my_index"])})  
                    }
                  }}>
                    {row["TweetText"]}
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}