import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
export default function BasicPopover(props) {
  const [xy, setxy] = React.useState([0,0]);
  const handleClose = () => {
    props.set_popper_open(false)
  };
  return (
    <div>
      <Popover
        id={props.myid}
        open={props.popper_open}
        onClose={handleClose}
        style={{ position: 'fixed', bottom: 0, right: 0, top: props.xy[1], left: props.xy[0] }}
      >
        <Typography sx={{ p: 2,cursor:"pointer" }} onClick={()=>{handleClose();props.handle_cluster_click()}}>Select All</Typography>
      </Popover>
    </div>
  );
}
