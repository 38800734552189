import React, { Component } from 'react';
import * as d3 from 'd3';
import { connect } from "react-redux";
import all_months_data from "./Processed data/all_months_data.json"
import "./App.css"

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { temp: 0, selected_month: "March", selected_topics: ["1"], selected_keywords: ["school"], keyword_freq: [] };
    }
    componentDidMount() {
        var temp_freq = {}
        Object.entries(all_months_data).map(item1 => {
            Object.entries(item1[1]).map(item2 => {
            
                item2[1].map(item3 => {
                    if (item3[0] in temp_freq) { temp_freq[item3[0]] += 1 }
                    else { temp_freq[item3[0]] = 1 }
                })
            })
        })
        this.setState({ keyword_freq: temp_freq })
        this.setState({selected_topics:Object.keys(all_months_data[this.state.selected_month])})
        this.setState({selected_keywords:all_months_data[this.state.selected_month]["1"].map(elem=>elem[0])})
        window.addEventListener('keypress', e => {
            if(e.key=="c"){this.setState({selected_keywords:[]})}
          });
    }
    render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ height: "100%", width: "100%" }}>
                    <Grid item xs={12} style={{ height: "100%" }}>
                        <div style={{ width: "100%", padding: 10, backgroundColor: "rgb(224, 224, 224,0.3)" }}>
                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                {['March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(item => <FormControlLabel value={item} control={<Radio checked={this.state.selected_month == item ? true : false}
                                    onChange={() => {
                                        this.setState({selected_topics:Object.keys(all_months_data[item])})
                                        this.setState({ selected_month: item })
                                    }} />} label={item} />)}
                            </RadioGroup>
                        </div>
                        <div>
                            {Object.keys(all_months_data[this.state.selected_month]).map(item => <p style={{ display: "inline-block", padding: "2px 3px", borderRadius: 5, margin: 5, backgroundColor: "rgb(224, 224, 224,0.4)", cursor: "pointer", border: this.state.selected_topics.includes(item) ? "1px solid gray" : null }} onClick={() => this.state.selected_topics.includes(item) ? this.setState({ selected_topics: this.state.selected_topics.filter(item2 => item != item2) }) : this.setState({ selected_topics: [...this.state.selected_topics, item] })}>{item!="-1"?"Topic " + item:"Other"}</p>)}
                        </div>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{height:window.innerHeight-110,overflow:"scroll"}}>
                            {this.state.selected_topics.map(item => <div style={{width: 180, textAlign: "center", marginLeft: 10}}><p style={{ fontSize: 18,cursor:"pointer",marginBottom:5 }} onClick={()=>this.setState({selected_keywords:all_months_data[this.state.selected_month][item].map(elem=>elem[0])})}>{item!="-1"?"Topic " + item:"Other"}</p>
                                {all_months_data[this.state.selected_month][item].map(item => <p style={{
                                    padding: "1px 2px", margin: "4px 4px", cursor: "pointer", fontWeight: this.state.selected_keywords.includes(item[0]) ? 600 : 400, color: this.state.selected_keywords.includes(item[0]) ? '#ff7f00' : "black",
                                    border: "1px solid rgb(188, 188, 188)"
                                }} onClick={() => this.state.selected_keywords.includes(item[0]) ? this.setState({ selected_keywords: this.state.selected_keywords.filter(item2 => item[0] != item2) }) : this.setState({ selected_keywords: [...this.state.selected_keywords, item[0]] })}>{item[0] + " " + item[1].toFixed(2) + " (" + this.state.keyword_freq[item[0]] + ")"}</p>)}</div>)}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const maptstateToprop = (state) => {
    return {
        selected_circles: state.selected_circles
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_original_data: (val) => dispatch({ type: "original_data", value: val }),
        Set_month_wise_data: (val) => dispatch({ type: "month_wise_data", value: val })
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);