import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MyTable from './MyTable';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Mark from "mark.js"
export default class Example extends Component {
    constructor(props) {
        super(props);
        this.state = { temp: 0, checked_value: "SubjectivityScore"};
    }
    componentDidMount(){
        this.setState({temp:10})
    }
    componentDidUpdate(){
        var context = document.querySelector("#table_container");
        var instance = new Mark(context);
        if(this.props.selected_phrase==null){
            var selected_phrase=[]
        }else{
            var selected_phrase=[this.props.selected_phrase,this.props.selected_phrase+",",this.props.selected_phrase+".","#"+this.props.selected_phrase,this.props.selected_phrase+"s",this.props.selected_phrase+":",this.props.selected_phrase+"."]
        }
        //instance.mark(selected_phrase,{"acrossElements":true,"accuracy":"exactly","separateWordSearch": false,"ignorePunctuation":[","]});    
        instance.mark(selected_phrase,{"acrossElements":true,"accuracy":"partially","separateWordSearch": false,"ignorePunctuation":[","]});    
    }
    render() {
        const { id, label, setProps, value } = this.props;
        var table_data = this.props.month_wise_data
        // First
        if(this.props.selected_circles.length>0){table_data=table_data.filter(item=>this.props.selected_circles.includes(item["my_index"]))}
        if(this.props.selected_topic!="none"){table_data=table_data.filter(item=>item['Topics']==this.props.selected_topic)}
        if(table_data.length>0){table_data.sort((a,b)=>a[this.state.checked_value]-b[this.state.checked_value])}
        
        // Sort based on phrase
        if(this.props.selected_phrase!=null){table_data=table_data.filter(item=>item['TweetText'].includes(this.props.selected_phrase))}
        
        var expert_table_data = table_data.filter(item => item['Label'] == "Expert")
        var non_expert_table_data = table_data.filter(item => item['Label'] == "Non Expert")
        return (
            <div id={id} key={table_data.length} style={{ width: "100%",height:"100%" }} className="table_parent_container">
                <Grid style={{ height: 55 }} container direction="row" justifyContent="center" alignItems="center">
                    <p style={{ marginRight: 14, fontSize: 18, marginTop: 15, fontWeight: 600 }}>Sort By:</p>
                    <FormControl>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel value="FlairSentimentScore" control={<Radio sx={{'&, &.Mui-checked': {color: '#828282'}}} checked={this.state.checked_value == "FlairSentimentScore" ? true : false} onClick={() => this.setState({ checked_value: "FlairSentimentScore" })} />} label="Sentiment" />
                            <FormControlLabel value="SubjectivityScore" control={<Radio  sx={{'&, &.Mui-checked': {color: '#828282'}}} checked={this.state.checked_value == "SubjectivityScore" ? true : false} onClick={() => this.setState({ checked_value: "SubjectivityScore" })} />} label="Objectivity" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid container direction="row" spacing={0.1} id="table_container">
                    <Grid item xs={6}>
                        {expert_table_data.length > 0 ? <MyTable id={id} data={expert_table_data} phrases={[]} setProps={setProps} value={value} header_text="Expert"></MyTable> : null}
                    </Grid>
                    <Grid item xs={6}>
                        {non_expert_table_data.length > 0 ? <MyTable id={id} data={non_expert_table_data} phrases={[]} setProps={setProps} value={value} header_text="Non Expert"></MyTable> : null}
                    </Grid>
                </Grid>
            </div>
        );
    }
}
