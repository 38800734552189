let initialState = {
   counter: 10,
   original_data: [],
   month_wise_data:[],
   default_month:"August",
   selected_topic:"COVID-19 Vaccines",
   selected_phrase:"convalescent plasma",
   selected_circles:[],
};
const reducer = (state = initialState, action) => {
   if (action.type === "selected_circles") {
      console.log(action.value)
      return { ...state, selected_circles: action.value }
   }
   if (action.type === "selected_phrase") {
      return { ...state, selected_phrase: action.value }
   }
   if (action.type === "selected_topic") {
      return { ...state, selected_topic: action.value }
   }
   if (action.type === "month_wise_data") {
      return { ...state, month_wise_data: action.value }
   }
   if (action.type === "original_data") {
      return { ...state, original_data: action.value }
   }
   return state;
};
export default reducer;
