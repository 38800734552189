import * as d3 from 'd3';
import * as $ from 'jquery'
import d3Cloud from "d3-cloud";
import * as d3lasso from 'd3-lasso';

export function Createtopic_viz(d1, groupedData, svg, item_width, item1_height, Label) {
    var color = { "Expert": "#1f78b4", "Non Expert": "#fdbf6f" }
    //-------------------------- Create Histogram Subjectivity
    var histogram_margin = { left: 5, right: 5 }
    var histogram_height = 30, histogram_width = item_width - (histogram_margin.left + histogram_margin.right)
    var subjectivity_g = svg.selectAll(".subjectivity_g").data([0]).join("g").attr("class", "subjectivity_g").attr("transform", "translate(" + 0 + "," + 0 + ")")
    var title_height = 22
    var space_for_ratio = 40
    var rect_height = 26
    var rect_width = histogram_width
    var text1_height = 20
    var data = d1.values.filter(item => item['Label'] == Label)
    subjectivity_g.selectAll(".Label").data([0]).join("text").attr("x", histogram_width / 2).attr('text-anchor', 'middle').attr("class", "Label").attr('dominant-baseline', "middle").attr("y", 8).text(Label).attr("font-size", 14).attr("font-weight", 600)
    //--------------------Rectangles
    subjectivity_g.selectAll(".border_rect").data([0]).join('rect').attr("class", "border_rect").style("fill", "none").style("stroke-width", 1)
        .attr("x", histogram_margin.left).attr("y", title_height).attr("width", histogram_width).attr("height", rect_height - 8).style("stroke", "#dddddd")
    subjectivity_g.selectAll(".my_rect").data([0]).join('rect').attr("class", "my_rect")
        .attr("width", rect_width * (data.length / d1.values.length)).style("fill", color[Label]).attr("x", histogram_margin.left).attr("y", title_height).attr("height", rect_height - 8).style("stroke", "#dddddd")
    subjectivity_g.selectAll(".score").data([0]).join("text").attr("x", data.length / d1.values.length > 0.7 ? 0.7 * rect_width : rect_width * (data.length / d1.values.length)).attr('text-anchor', 'start')
        .attr("class", "score").attr('dominant-baseline', "middle").attr("y", title_height + (rect_height - 6) / 2).text(data.length).attr("font-size", 12)
    subjectivity_g.selectAll(".total_score").data([0]).join("text").attr("x", rect_width/2).attr('text-anchor', 'middle')
        .attr("class", "total_score").attr('dominant-baseline', "middle").attr("y", (title_height + rect_height)).text(d1.values.length).attr("font-size", 10)
    //--------------------

    subjectivity_g.selectAll(".objectivity_title").data([0]).join("text").attr("x", histogram_width / 2).attr('text-anchor', 'middle').attr("class", "objectivity_title").attr('dominant-baseline', "middle").attr("y", space_for_ratio + title_height + 8).text('Objectivity').attr("font-size", 12)
    var x = d3.scaleLinear().domain([0, 1]).range([0, histogram_width])
    subjectivity_g.selectAll(".histogram_svg_subjectivity").data([0]).join("svg").attr("x", histogram_margin.left).attr("class", "histogram_svg_subjectivity").attr("width", histogram_width).attr("height", histogram_height).attr('y', title_height + space_for_ratio + text1_height)
        .attr("add_histogram", function () {
            // Bins of subjectivity score
            var histogram = d3.histogram().value(d => d['SubjectivityScore']).thresholds(25)
            var bins = histogram(data).map(item => { return { "count": item.length, "x0": item.x0, "x1": item.x1 } })
            var y = d3.scaleLinear().domain([0, d3.max(bins.map(item => item['count']))]).range([histogram_height, 0])
            d3.select(this).selectAll(".subjectivity_path").data([0]).join("path").attr("class", "subjectivity_path").attr("fill", color[Label])
                .attr("d", d => d3.area().x(d => x(d["x0"])).y0(y(0)).y1(d => y(d['count'])).curve(d3.curveMonotoneX)(bins)).attr('opacity', 1).attr("data", JSON.stringify(bins))
            //d3.select(this).selectAll(".average_line").data([average]).join("line").attr("class", "average_line").attr("x1", d => x(d)).attr("x2", d => x(d)).attr("y1", 0).attr("y2", item1_height).attr("stroke-width", 1).attr("stroke", "gray").attr("avg", d => d)
        })
    var text2_height = 20
    subjectivity_g.selectAll(".my_line").data([0]).join("line").attr("class", "my_line").attr("x1", histogram_margin.left).attr("x2", histogram_width).attr("y1", title_height + space_for_ratio + text1_height + histogram_height).attr("y2", title_height + space_for_ratio + text1_height + histogram_height).attr("stroke-width", 1).attr("stroke", "#d6d6d6")
    subjectivity_g.selectAll(".myText2").data([[0.1, "least", "start"], [1, "most", "end"]]).join("text").attr("x", d => x(d[0])).attr('text-anchor', d => d[2]).attr("class", "myText2").attr('dominant-baseline', "middle").attr("y", title_height + space_for_ratio + text1_height + histogram_height + 10).text(d => d[1]).attr("font-size", 8)
    //subjectivity_g.selectAll(".myText2").data([[0.1,"0","start"],[1,"1","end"]]).join("text").attr("x", d=>x(d[0])).attr('text-anchor',d=>d[2]).attr("class", "myText2").attr('dominant-baseline',"middle").attr("y",title_height+space_for_ratio+text1_height+histogram_height+10).text(d=>d[1]).attr("font-size",8)
    //-------------------------- Create Histogram Subjectivity ends

    //-------------------------- Create Histogram Sentiment
    var text3_height = 15
    var sentiment_g_y_transform = title_height + space_for_ratio + text1_height + histogram_height + text2_height + text3_height
    var sentiment_g = svg.selectAll(".sentiment_g").data([0]).join("g").attr("class", "sentiment_g").attr("transform", "translate(" + 0 + "," + sentiment_g_y_transform + ")")

    sentiment_g.selectAll(".sentiment_title").data([0]).join("text").attr("x", histogram_width / 2).attr('text-anchor', 'middle').attr("class", "sentiment_title").attr('dominant-baseline', "middle").attr("y", 0).text('Sentiment').attr("font-size", 12)
    var x = d3.scaleLinear().domain([-1, 1]).range([0, histogram_width])
    sentiment_g.selectAll(".histogram_svg_sentiment").data([0]).join("svg").attr("x", histogram_margin.left).attr("class", "histogram_svg_sentiment").attr("width", histogram_width).attr("height", histogram_height).attr('y', text3_height)
        .attr("add_histogram", function () {
            var data2 = d1.values.filter(item => item['Label'] == Label)
            // Bins of subjectivity score
            var histogram = d3.histogram().value(d => d['FlairSentimentScore']).thresholds(25)
            var bins = histogram(data2).map(item => { return { "count": item.length, "x0": item.x0, "x1": item.x1 } })
            var y = d3.scaleLinear().domain([0, d3.max(bins.map(item => item['count']))]).range([histogram_height, 0])
            d3.select(this).selectAll(".sentiment_path").data([0]).join("path").attr("class", "sentiment_path").attr("fill", color[Label])
                .attr("d", d => d3.area().x(d => x(d["x0"])).y0(y(0)).y1(d => y(d['count'])).curve(d3.curveMonotoneX)(bins)).attr('opacity', 1).attr("data", JSON.stringify(bins))
        })
    sentiment_g.selectAll(".my_line").data([0]).join("line").attr("class", "my_line").attr("x1", histogram_margin.left).attr("x2", histogram_width).attr("y1", text3_height + histogram_height).attr("y2", text3_height + histogram_height).attr("stroke-width", 1).attr("stroke", "#d6d6d6")
    sentiment_g.selectAll(".myText2").data([[-.91, "negative", "start"], [1, "positive", "end"]]).join("text").attr("x", d => x(d[0])).attr('text-anchor', d => d[2]).attr("class", "myText2").attr('dominant-baseline', "middle").attr("y", text3_height + histogram_height + 10).text(d => d[1]).attr("font-size", 8)
    //sentiment_g.selectAll(".myText2").data([[-.9,"-1","start"],[0,"0","middle"],[1,"1","end"]]).join("text").attr("x", d=>x(d[0])).attr('text-anchor',d=>d[2]).attr("class", "myText2").attr('dominant-baseline',"middle").attr("y",text3_height+histogram_height+10).text(d=>d[1]).attr("font-size",12)
    //-------------------------- Create Histogram Sentiment ends
}
export function CreatewordCloud(d1, svg2, svg2_width, svg2_height) {
    //svg.selectAll(".my_rect").data([0]).join('rect').attr("class", "my_rect").attr("width", "100%").attr("height", "100%").style("fill", "grey")
    var word_feq = get_word_frequency(d1.values)
    //var data = [{ text: "Hello", value: 6260 },{ text: "happy", value: 5370 },{ text: "beautiful", value: 2480 },{ text: "rainbow", value: 4350 },{ text: "unicorn", value: 1250 },{ text: "glitter", value: 3140 },{ text: "happy", value: 990 },{ text: "pie", value: 4230 }];
    var top_ten_words = word_feq.slice(0, 10)
    var font_scale = d3.scaleLinear().domain(d3.extent(top_ten_words.map(item => item[1]))).range([10, 18])
    var layout = d3Cloud().size([svg2_width, svg2_height])
        .words(top_ten_words.map(item => { return { "text": item[0], "value": item[1] } }))
        .padding(10)
        .fontSize(12)
        .on("end", draw);
    layout.start();
    function draw(words) {
        svg2.selectAll("g").data([0]).join('g').attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
            .selectAll("text").data(words).join("text").text((d) => d.text)
            .style("font-size", d => font_scale(d.value)).style("font-family", (d) => d.font)
            .style("fill", '').attr("text-anchor", "middle")
            .attr("transform", (d) => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")");
    }
}
function get_word_frequency(word_freq_data) {
    var temp_word_freq = {}
    word_freq_data.map(item => {
        var words_arr = item['CleanTexttokens'].split(" ")
        words_arr.map(item2 => { if (item2 in temp_word_freq) { temp_word_freq[item2] += 1 } else { temp_word_freq[item2] = 1 } })
    })
    var temp_word_freq_arr = Object.entries(temp_word_freq).sort((a, b) => b[1] - a[1])
    return temp_word_freq_arr
}

export function create_scatterpolot_cluster(cluster_data, parent_width, parent_height, svg, selected_circles, Set_selected_circles,Set_selected_phrase) {
    var color = { "Expert": "rgb(31, 120, 180,0.7)", "Non Expert": "rgb(253, 191, 111,0.7)" }
    var margin = { left: 8, top: 8, right: 8, bottom: 8 }
    var x = cluster_data.map(item => item['KMeans_x_component'])
    var y = cluster_data.map(item => item['KMeans_y_component'])

    var x_Scale = d3.scaleLinear().domain(d3.extent(x)).range([margin.left, parent_width - margin.right]).nice();
    var y_Scale = d3.scaleLinear().domain(d3.extent(y)).range([margin.top, parent_height - margin.bottom]).nice();
    //var svg = d3.select(".cluster_svg")
    var circles = svg.selectAll(".cluster_circle").data(cluster_data).join('circle').attr('class', "cluster_circle").attr("cx", d => x_Scale(d["KMeans_x_component"]))
        .attr("cy", d => y_Scale(d["KMeans_y_component"])).attr("r", 5).attr("fill", d => color[d['Label']]).attr("opacity", d => selected_circles.length > 0 && !selected_circles.includes(d["my_index"]) ? 0.2 : 1)

    // Lasso functions
    var lasso_start = function () {
        lasso.items().classed("not_possible", true).classed("selected", false)
    };

    var lasso_draw = function () {
        lasso.possibleItems().classed("not_possible", false).classed("possible", true);
        lasso.notPossibleItems().classed("not_possible", true).classed("possible", false);
    };
    var lasso_end = function () {
        var selected_index = []
        lasso.selectedItems().each(function () {
            selected_index.push(d3.select(this).data()[0]['my_index'])
        })
        if(selected_index.length>0){
            Set_selected_circles(selected_index)
            Set_selected_phrase(null)    
        }
        lasso.items().classed("not_possible", false).classed("possible", false);
        lasso.selectedItems().classed("selected", true)
        lasso.notSelectedItems()
    };

    var lasso = d3lasso.lasso().closePathSelect(true)
        .closePathDistance(100).items(circles).targetArea(svg).on("start", lasso_start).on("draw", lasso_draw).on("end", lasso_end);
    svg.call(lasso);
}

export function create_phrase_view(phrase_count, svg1, svg1_width, svg1_height, selected_phrase) {
    svg1.selectAll(".topic_name").data([0]).join("foreignObject").attr("class", "topic_name").attr("x", 0).attr("y", 0)
        .attr("width", svg1_width)
        .attr("height", svg1_height)
        .selectAll('.foreigndiv').data([0]).join("xhtml:div").attr('class', 'foreigndiv')
        .style("width", svg1_width + "px").style("height", svg1_height + "px").style("margin-top", '0px')
        .style("background", "").style("font-size", "13px")
        .selectAll("p").data(phrase_count.slice(0, 5)).join("p").text(d => d[0]).style("opacity", d => d[0] == selected_phrase ? 1 : 0.5).classed("selected_phrase", d => d[0] == selected_phrase ? true : false)
}

//-----------------------------------------Create Metrics
export function create_matrix(data, selected_quadrant, set_quadrant_data) {
    var color = { "Expert": "rgb(31, 120, 180,0.7)", "Non Expert": "rgb(253, 191, 111,0.7)" }
    var margin = { space_for_top_histogram: 0, space_for_bottom_histogram: 30, space_for_left_histogram: 30, right: 5 },
        parent_width = parseInt($(".matrix_svg").width()),
        parent_height = parseInt($(".matrix_svg").height());

    var q_data = { "q1": [], "q2": [], "q3": [], "q4": [] }
    data.map(item => {
        if (item['SubjectivityScore'] <= 0.5 && item['FlairSentimentScore'] <= 0) { if ("q1" in q_data) { q_data["q1"].push(item) } else { q_data["q1"] = [item] } }
        if (item['SubjectivityScore'] >= 0.5 && item['FlairSentimentScore'] <= 0) { if ("q2" in q_data) { q_data["q2"].push(item) } else { q_data["q2"] = [item] } }
        if (item['SubjectivityScore'] >= 0.5 && item['FlairSentimentScore'] >= 0) { if ("q3" in q_data) { q_data["q3"].push(item) } else { q_data["q3"] = [item] } }
        if (item['SubjectivityScore'] <= 0.5 && item['FlairSentimentScore'] >= 0) { if ("q4" in q_data) { q_data["q4"].push(item) } else { q_data["q4"] = [item] } }
    })
    var matrix_width = parent_width - (margin.space_for_left_histogram + margin.right), matrix_height = parent_height - (margin.space_for_top_histogram + margin.space_for_bottom_histogram)
    var svg = d3.select(".matrix_svg").selectAll(".matrix_scatter_svg2").data([0]).join("svg").attr("class", "matrix_scatter_svg2").attr("x", margin.space_for_left_histogram).attr("width", matrix_width).attr("y", margin.space_for_top_histogram).attr("height", matrix_height)
    svg.selectAll(".quadrants").data([4, 3, 1, 2]).join('svg').attr('class', "quadrants").attr("width", matrix_width / 2).attr("height", matrix_height / 2).attr("x", (d, i) => i % 2 == 0 ? 0 : matrix_width / 2).attr("y", (d, i) => i < 2 ? 0 : matrix_height / 2).attr("quadrant", d => d)
        .attr("opacity", d => selected_quadrant!=0 && selected_quadrant != d ? 0.3 : 1)
        .attr("add_quadrants", function (d) {
            generate_quadrant(d3.select(this), d, matrix_width / 2, matrix_height / 2, d)
        })

    //----- Create word frequency ends here
    function generate_quadrant(selection, quadrant, width, height, d) {
        var data = q_data["q" + quadrant]
        selection.selectAll(".border_rect").data([0]).join('rect').attr("class", "border_rect").attr("width", "100%").attr("height", "100%").style("stroke", "#cccccc").style("fill", "white").style("stroke-width", 1).style("cursor", "pointer")
            .on("click", () => set_quadrant_data(q_data["q" + d], d))
        var x_range = [0, 1], y_range = [-1, 0]
        if (quadrant == 1) { x_range = [0, 0.5]; y_range = [-1, 0] }
        if (quadrant == 2) { x_range = [0.5, 1]; y_range = [-1, 0] }
        if (quadrant == 3) { x_range = [0.5, 1]; y_range = [0, 1] }
        if (quadrant == 4) { x_range = [0, 0.5]; y_range = [0, 1] }
        var x_Scale = d3.scaleLinear().domain(x_range).range([8, width - 8])
        var y_Scale = d3.scaleLinear().domain(y_range).range([height - 8, 8])
        selection.selectAll(".q_circle").data(data).join('circle').attr('class', "q_circle").attr("cx", d => x_Scale(d["SubjectivityScore"]))
            .attr("cy", d => y_Scale(d["FlairSentimentScore"])).attr("r", 5).attr("fill", d => color[d['Label']]).attr("data", d => JSON.stringify("sub: " + d["SubjectivityScore"] + " sent:" + d["FlairSentimentScore"]))

        d3.select(".matrix_svg").selectAll(".objectvity_label").data([0]).join("text").attr("x", (2 * margin.space_for_left_histogram + matrix_width) / 2).attr('text-anchor', 'middle').attr("class", "objectvity_label").attr('dominant-baseline', "middle").attr("y", matrix_height + margin.space_for_top_histogram + 20).text("Objectivity").attr("font-size", 14).attr("font-weight", 400)

        d3.select(".matrix_svg").selectAll(".sentiment_label").data([0]).join("text")
            .attr('text-anchor', 'middle').attr("class", "sentiment_label").attr('dominant-baseline', "middle")
            .text("Sentiment").attr("font-size", 14).attr("font-weight", 400)
            .attr('transform', d => "rotate(-90," + (matrix_height + margin.space_for_top_histogram) / 2 + "," + 10 + ")").attr("x", 5).attr("y", -matrix_width / 1.9)
    }
}
export function create_bar(data) {
    var color = { "Expert": "rgb(31, 120, 180,0.7)", "Non Expert": "rgb(253, 191, 111,0.7)" }
    var parent_width = parseInt($(".bar_chart_svg").width()),
        parent_height = parseInt($(".bar_chart_svg").height());
    var grouped_data = d3.nest().key((d) => d['Concept']).entries(data).sort((a, b) => b['values'].length - a['values'].length)
    //console.log(grouped_data)
    var margin = { top: 10, right: 30, bottom: 10, left: 100 },
        width = parent_width - margin.left - margin.right,
        height = parent_height - margin.top - margin.bottom;

    var svg = d3.select(".bar_chart_svg").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).selectAll(".myg").data([0]).join("g").attr("class", "myg")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var x = d3.scaleLinear().domain([0, d3.max(grouped_data.map(item => item.values.length))]).range([0, width]);
    var y = d3.scaleBand().range([0, height]).domain(grouped_data.map(item => item.key)).padding(.1);

    svg.selectAll(".myRect").data(grouped_data).join("rect").attr("class", "myRect")
        .attr("x", x(0))
        .attr("y", d => y(d.key))
        .attr("width", d => x(d.values.length))
        .attr("height", y.bandwidth())
        .attr("fill", "rgb(142, 142, 142,0.85)")
    svg.selectAll(".myg2").data([0]).join("g").attr("class", "myg2").call(d3.axisLeft(y)).selectAll("text").attr("font-size", 13)
    svg.select(".domain").attr("stroke", "rgb(188, 188, 188)")
    svg.selectAll("line").attr("stroke", "rgb(188, 188, 188)")
}