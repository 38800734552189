import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import * as d3 from 'd3';
import * as $ from 'jquery'
import { connect } from "react-redux";
import * as algo from "./Algo"
class App extends Component {
    constructor(props) {
        super(props);
        this.state = { temp: 0, selected_topic: "none"};
    }
    componentDidMount() {
        this.setState({ temp: 10 })
    }
    componentDidUpdate(prevProps, prevState) {
        var self=this
        var item_width = this.props.item_width
        var item_height = this.props.item_height
        var svg1_height = 60, item1_top_margin = 0
        var svg1_width = item_width
        var svg2_height = item_height - (svg1_height + item1_top_margin + 8)
        var svg2_width = item_width
        //-------------------------------------------------
        var cluster_data= this.props.data.values
        var bigram_data=cluster_data.map(item=>{
            var temp=eval(item['BigramOccur'])
            //.replace("[","").replace("]","").replace(/["']/g, "").split(",").map(item=>item.trim())
            return temp
        })
        var svg = d3.select("#" + this.props.myid)
        svg.selectAll(".border_rect").data([0]).join('rect').attr("class", "border_rect").attr("width", "100%").attr("height", "100%").style("stroke", "rgb(188, 188, 188,0.5)").style("fill", "none").style("stroke-width", 1);
    
        var svg1=svg.selectAll(".svg1").data([0]).join('svg').attr("class","svg1").attr("x",0).attr("y",item1_top_margin).attr("width",item_width).attr("height",svg1_height)
        svg1.selectAll(".border_rect").data([0]).join('rect').attr("class", "border_rect").attr("width", "100%").attr("height", "100%").style("stroke", "rgb(188, 188, 188,0.5)").style("fill", "none").style("stroke-width", 1);
        var phrase_count=this.phrase_count_generator(bigram_data)
        algo.create_phrase_view(phrase_count,svg1,svg1_width,svg1_height,this.props.selected_phrase)       
        
        var svg2=svg.selectAll(".svg2").data([0]).join('svg').attr("class","svg2").attr("x",0).attr("y",svg1_height+item1_top_margin+2).attr("width",svg2_width).attr("height",svg2_height)
        svg2.selectAll(".my_rect").data([0]).join('rect').attr("class", "my_rect").attr("width", "100%").attr("height", '100%').style("fill", "transparent")
        algo.create_scatterpolot_cluster (cluster_data,svg2_width,svg2_height,svg2,this.props.selected_circles,this.props.Set_selected_circles,this.props.Set_selected_phrase)
        //svg2.selectAll(".my_rect").data([0]).join('rect').attr("class", "my_rect").attr("width", "100%").attr("height", '100%').style("fill", "grey")
        svg1.selectAll("p").on("click",function(){
            if(d3.select(this).text()==self.props.selected_phrase){self.props.Set_selected_phrase(null)}
            else{self.props.Set_selected_phrase(d3.select(this).text())}
            d3.select(this).classed("selected_phrase",true)
        })
    
    }
    phrase_count_generator=(bigram_data)=>{
        var temp_dict={}
        bigram_data.map(item2=>{
            item2.map(item=>{
                if(item in temp_dict){
                    temp_dict[item]=temp_dict[item]+1
                }
                else{temp_dict[item]=1}
            })
        })
        var phrases_data=Object.entries(temp_dict).sort((a,b)=>b[1]-a[1]).filter(item=>item[0].length>1 && item[1]>0)
        return phrases_data
    }
    render() {
        return (
            <svg id={this.props.myid} style={{width:this.props.item_width,height:this.props.item_height}}> </svg>
        );
    }
}

const maptstateToprop = (state) => {
    return {
        original_data: state.original_data,
        month_wise_data: state.month_wise_data,
        default_month: state.default_month,
        selected_topic: state.selected_topic,
        selected_circles:state.selected_circles,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_original_data: (val) => dispatch({ type: "original_data", value: val }),
        Set_month_wise_data: (val) => dispatch({ type: "month_wise_data", value: val }),
        Set_selected_topic: (val) => dispatch({ type: "selected_topic", value: val }),
        Set_selected_circles: (val) => dispatch({ type: "selected_circles", value: val }),
        Set_selected_phrase: (val) => dispatch({ type: "selected_phrase", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);


//https://www.d3indepth.com/force-layout/
